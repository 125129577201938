import React, { useState, useEffect } from 'react'
import pdfFile from '../../assets/Athlenergy-Whitepaper.pdf'
import Classes from './Home.module.css'
const image1 = '/image1.png';
const image2 = '/image2.png';
const image3 = '/image3.png';
const partner1 = '/partner1.png'
const partner2 = '/partner2.png'
const partner3 = '/partner3.png'
const partner4 = '/partner4.png'
const partner5 = '/partner5.png'
const slider1 = '/slider1.png'
const slider2 = '/slider2.png'

export default function SectionD() {
    const [activeBox, setactiveBox] = useState(0); //set active to 1 if needed
    const [activeNavigation, setactiveNavigation] = useState(1);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setactiveNavigation(prev => (prev < 3 ? prev + 1 : 1));
    //     }, 10000);

    //     // Cleanup interval on component unmount
    //     return () => clearInterval(interval);
    // }, []);

    const handleNext = () => {
        if (activeNavigation <= 2) {
            setactiveNavigation(activeNavigation + 1);
        }
    }
    const handlePrev = () => {
        if (activeNavigation > 1) {
            setactiveNavigation(activeNavigation - 1);
        }
    }

    const handleDownloadPDF = () => {
        const link = document.createElement('a');
        link.href = pdfFile; // Use the imported PDF file
        link.download = 'Athlenergy Whitepaper.pdf'; // Default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={Classes.sectionD} >
            <h1>Unsere Arbeit im Detail</h1>
            {/* <h5>Du bekommst von uns:</h5> */}
            <div className={Classes.sectionDCon} >
                <div
                    className={Classes.sectionDBox}
                    // onClick={() => { setactiveBox(1) }}
                    // style={{
                    //     border: activeBox === 1 ? '1px solid #af73fccc' : '',
                    //     background: activeBox === 1 ? 'linear-gradient(45deg, rgba(0, 0, 0, 1), transparent)' : ''
                    // }}
                >
                    <img src={image1} alt='image' />
                    <div className={Classes.para2} >
                        <h4 style={{ color: activeBox === 1 ? '#af73fccc' : '' }} >Leistungsprofil</h4>
                        <h5>
                        Verschiedene Positionen auf dem Feld verlangen nach ganz unterschiedlichen Fähigkeiten. Jedes Spielsystem erfordert eigene Kompetenzen. Deshalb erstellen wir für jeden Spieler ein individuelles Leistungsprofil, in dem die Stärken, Schwächen und Chancen der Spieler detailliert aufgezeigt werden.
                        </h5>
                    </div>
                </div>
                <div
                    className={Classes.sectionDBox}
                    // onClick={() => { setactiveBox(2) }}
                    // style={{
                    //     border: activeBox === 2 ? '1px solid #af73fccc' : '',
                    //     background: activeBox === 2 ? 'linear-gradient(45deg, rgba(0, 0, 0, 1), transparent)' : ''
                    // }}
                >
                    <img src={image2} alt='image' />
                    <div className={Classes.para2} >
                        <h4 style={{ color: activeBox === 2 ? '#af73fccc' : '' }} >Konzept</h4>
                        <h5>
                        Die Trainingsbedingungen in jedem Verein sind unterschiedlich. Unsere Aufgabe ist es, sorgfältig zu analysieren, welche Möglichkeiten eure Anlagen bieten, und ein individuelles Konzept zu erstellen, das eurer Mannschaft eine optimale Weiterentwicklung ermöglicht.
                        </h5>
                    </div>
                </div>
                <div
                    className={Classes.sectionDBox}
                    // onClick={() => { setactiveBox(3) }}
                    // style={{
                    //     border: activeBox === 3 ? '1px solid #af73fccc' : '',
                    //     background: activeBox === 3 ? 'linear-gradient(45deg, rgba(0, 0, 0, 1), transparent)' : ''
                    // }}
                >
                    <img src={image3} alt='image' />
                    <div className={Classes.para2} >
                        <h4 style={{ color: activeBox === 3 ? '#af73fccc' : '' }} >Fokus</h4>
                        <h5>
                        Unser Ziel ist es, euren Trainern zu ermöglichen, sich vollständig auf ihre Stärke, das Fußballtraining, zu fokussieren. Wir übernehmen die komplette athletische Planung und stellen euren Trainern die Werkzeuge zur Verfügung, um ihr Training noch effektiver zu gestalten.
                        </h5>
                    </div>
                </div>
                <div className={Classes.SectionDConBottom} >
                    <h5>Das ganze Programm in einem Dokument:</h5>
                    <svg xmlns="http://www.w3.org/2000/svg" height={'20px'} viewBox="0 0 384 512"><path fill="#ffffff" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                    <button onClick={handleDownloadPDF}> <h5>Download PDF</h5> </button>
                </div>
                {/* <div className={Classes.partnersCon} >
                    <h4>Unsere Partner</h4>
                    <div className={Classes.partenersList} >
                        <div>
                            <img src={partner1} style={{ height: '7rem' }} alt='image' />
                        </div>
                        <div>
                            <img src={partner2} style={{ height: '7rem' }} alt='image' />
                        </div>
                        <div>
                            <img src={partner3} style={{ height: '6rem' }} alt='image' />
                        </div>
                        <div>
                            <img src={partner4} style={{ height: '6rem' }} alt='image' />
                        </div>
                        <div>
                            <img src={partner5} style={{ height: '8rem' }} alt='image' />
                        </div>
                    </div>
                </div> */}
                <div className={Classes.partnersCon} >
                    <h4 >Neuigkeiten</h4>
                    <div className={Classes.sliderCon} >
                        {activeNavigation === 1 &&
                            <>
                                <div className={Classes.sliderItem} >
                                    <div className={Classes.sliderImgCon} >
                                        <img src={slider1} />
                                    </div>
                                    <h5 >
                                        Unser Athlet Janik H. erzielt den 3. Platz bei der NAGA Germany Grappling Championship.
                                    </h5>
                                </div>
                                <div className={Classes.sliderItem} >
                                    <div className={Classes.sliderImgCon} >
                                        <img src={slider2} />
                                    </div>
                                    <h5 >
                                        Athlenergy hält Seminar & Leistungsdiagnostik bei ASV Rauschenberg.
                                    </h5>
                                </div>
                            </>
                        }
                        {activeNavigation === 2 &&
                            <>
                                <div className={Classes.sliderItem} >
                                    <div className={Classes.sliderImgCon} >
                                        <img src={slider2} />
                                    </div>
                                    <h5 >
                                        Neues Trainingsequipment betritt den Platz: Leistungsmesser C900
                                        und Brower Training Systems.
                                    </h5>
                                </div>
                                <div className={Classes.sliderItem} >
                                    <div className={Classes.sliderImgCon} >
                                        <img src={slider1} />
                                    </div>
                                    <h5 >
                                        Johann Johansen gewinnt dern ersten Platz im Jiu-Jitsu Tournament,
                                        begleitet von unserem Athletiktrainer Lukas.
                                    </h5>
                                </div>
                            </>
                        }
                        {activeNavigation === 3 &&
                            <>
                                <div className={Classes.sliderItem} >
                                    <div className={Classes.sliderImgCon} >
                                        <img src={slider1} />
                                    </div>
                                    <h5 >
                                        Johann Johansen gewinnt dern ersten Platz im Jiu-Jitsu Tournament,
                                        begleitet von unserem Athletiktrainer Lukas.
                                    </h5>
                                </div>
                                <div className={Classes.sliderItem} >
                                    <div className={Classes.sliderImgCon} >
                                        <img src={slider2} />
                                    </div>
                                    <h5 >
                                        Neues Trainingsequipment betritt den Platz: Leistungsmesser C900
                                        und Brower Training Systems.
                                    </h5>
                                </div>
                            </>
                        }
                    </div>
                    <div className={Classes.sliderBottom} >
                        <div className={Classes.pagination} >
                            <div className={Classes.dot} onClick={() => { setactiveNavigation(1) }} style={{ background: activeNavigation === 1 ? 'white' : '#ffffff73' }} ></div>
                            {/* <div className={Classes.dot} onClick={() => { setactiveNavigation(2) }} style={{ background: activeNavigation === 2 ? 'white' : '#ffffff73' }} ></div>
                            <div className={Classes.dot} onClick={() => { setactiveNavigation(3) }} style={{ background: activeNavigation === 3 ? 'white' : '#ffffff73' }} ></div> */}
                        </div>
                        <div className={Classes.navigation} >
                            <button onClick={handlePrev} disabled={activeNavigation === 1} >
                                <svg fill={activeNavigation === 1 ? '#ffffff73' : 'white'} height='20px' cursor='pointer' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                            </button>
                            <button onClick={handleNext} disabled={activeNavigation === 1} >
                                <svg fill={activeNavigation === 1 ? '#ffffff73' : 'white'} height='20px' cursor='pointer' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" /></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
