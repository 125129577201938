import React from 'react'
import Classes from './styles.module.css'

export default function Profile1Popup({ closePopup }) {
    const handleBgClick = () => {
        closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card}
                onClick={(event) => { handleCardClick(event) }}
            >
                <div className={Classes.header} >
                    <div></div>
                    <h5>Biografie</h5>
                    <div className={Classes.close} onClick={closePopup} >
                        <svg
                            id="fi_2723639"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 3"
                            fill='white'
                        >
                            <path d="m17.414 16 6.293-6.293a1 1 0 0 0 -1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0 -1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a1 1 0 0 0 1.414-1.414z"></path>
                        </svg>
                    </div>
                </div>
                <div className={Classes.body} >
                    <div>
                        <h1>Lukas <br /> Lüttmann</h1>
                        <h5 style={{ color: '#747474' }} >Atlehtiktrainer</h5>
                    </div>
                    <div className={Classes.width18} >
                        <div className={Classes.card_img} >
                            <img src={'/profile1-mini.png'} className={Classes.card_inner_img} />
                        </div>
                        <h5>
                        Fußball ist eine Leidenschaft, die jeder in unserem Team teilt. Die Erfahrungen, die ich im Leistungssport als Athletiktrainer sammeln konnte, möchte ich an Vereine auf jedem Niveau weitergeben. Im Endeffekt lieben wir alle den Sport und möchten der beste Spieler werden, der wir sein können. Dafür benötigt es bestmögliche Betreuung auf jeder Ebene.                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
