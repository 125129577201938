import React from 'react';
import Classes from './Navbar.module.css';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
    const navigate = useNavigate();

    const scrollToFooter = () => {
        const footer = document.getElementById('contactFooter');
        if (footer) {
            footer.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={Classes.navbar}>
            <div>
                <a href='/'>
                    <h5 className={Classes.logo}> ATHLENERGY </h5>
                </a>
            </div>
            <div>
                <div className={Classes.navRight}>
                    <div onClick={() => navigate('/about')}>
                        <h5>Über uns</h5>
                    </div>
                    <div>
                        <h5>Impressum</h5>
                    </div>
                    <div onClick={scrollToFooter}>
                        <h5>Kontakt</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
