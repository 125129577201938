import React from 'react'
import Classes from './styles.module.css'

export default function Profile3Popup({ closePopup }) {
    const handleBgClick = () => {
        closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card}
                onClick={(event) => { handleCardClick(event) }}
            >
                <div className={Classes.header} >
                    <div></div>
                    <h5>Biografie</h5>
                    <div className={Classes.close} onClick={closePopup} >
                        <svg
                            id="fi_2723639"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 3"
                            fill='white'
                        >
                            <path d="m17.414 16 6.293-6.293a1 1 0 0 0 -1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0 -1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a1 1 0 0 0 1.414-1.414z"></path>
                        </svg>
                    </div>
                </div>
                <div className={Classes.body} >
                    <div>
                        <h1>Thorge <br /> Holst</h1>
                        <h5 style={{ color: '#747474' }} >Atlehtiktrainer</h5>
                    </div>
                    <div className={Classes.width18} >
                        <div className={Classes.card_img} >
                            <img src={'/profile3-mini.png'} className={Classes.card_inner_img} />
                        </div>
                        <h5>
                            Hi, ich bin Thorge. 
                            <br/>
                            <br/>
                            Ich bin studierter Sportwissenschaftler und habe meinen Abschluss an der Goethe-Universität in Frankfurt absolviert. Zu meinen kompetenzen gehören Personal Training,  Rehabilitatives Training bei Sportverletzungen und Sportschäden, Sportpsychologische Diagnostik,  Sportmedizinische Diagnostik, Muskelkfunktionsdiagnostik und physiotherapeutische Interventionen sowie Athletiktraining und Ernährungsberatung.
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
