import React from 'react'
import Classes from './Home.module.css'
import { AnimateTopToBottom } from '../../Components/Animations/AnimateTopToBottom'

export default function SectionB() {
    return (
        <div className={Classes.sectionB} >
            <AnimateTopToBottom delay={0.3} >
                <h1>
                    “Sport science is the biggest and most <br /> important change in my lifetime”
                </h1>
                <h5>Sir Alex Ferguson - 26 Jahre Cheftrainer von Manchester United </h5>
            </AnimateTopToBottom>
        </div>
    )
}
