import React, { useEffect, useState } from 'react';
import Classes from './Home.module.css';
const backgroundImg = '/home.png';
const arrowDown = '/arrowDown.png';

export default function SectionA() {
    const [showArrow, setShowArrow] = useState(false);

    useEffect(() => {
        let timer;

        const handleScroll = () => {
            setShowArrow(false);
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timer);
        };

        timer = setTimeout(() => {
            setShowArrow(true);
        }, 10000);

        window.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={Classes.sectionA}>
            <img src={backgroundImg} alt='background' className={Classes.section_img} />
            <div className={Classes.hero1Con}>
                <h1><span className={Classes.ml}>PERFORM</span><br />BETTER</h1>
                <h5>
                    Leistungsdiagnostik und Strategieentwicklung, für<br />
                    Vereine, die den nächsten Schritt in ihrer<br />
                    sportlichen Entwicklung anstreben.
                </h5>
            </div>
            <div className={Classes.arrowDownCon}>
                {showArrow && (
                    <img src={arrowDown} alt='arrow' className={Classes.pulsingArrow} />
                )}
            </div>
        </div>
    );
}
