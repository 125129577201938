import React from 'react'
import SectionA from './SectionA'
import SectionB from './SectionB'
import SectionC from './SectionC'
import SectionD from './SectionD'

export default function Home() {
  return (
    <>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
    </>
  )
}
