import React from 'react'
import Classes from './styles.module.css'

export default function Profile2Popup({ closePopup }) {
    const handleBgClick = () => {
        closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card}
                onClick={(event) => { handleCardClick(event) }}
            >
                <div className={Classes.header} >
                    <div></div>
                    <h5>Biografie</h5>
                    <div className={Classes.close} onClick={closePopup} >
                        <svg
                            id="fi_2723639"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 3"
                            fill='white'
                        >
                            <path d="m17.414 16 6.293-6.293a1 1 0 0 0 -1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0 -1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a1 1 0 0 0 1.414-1.414z"></path>
                        </svg>
                    </div>
                </div>
                <div className={Classes.body} >
                    <div>
                        <h1>Fisnik <br /> Avdija</h1>
                        <h5 style={{ color: '#747474' }} >Atlehtiktrainer</h5>
                    </div>
                    <div className={Classes.width18} >
                        <div className={Classes.card_img} >
                            <img src={'/profile2-mini.png'} className={Classes.card_inner_img} />
                        </div>
                        <h5>
                        Marketing bzw. die Kunst, anderen Menschen zu zeigen, dass man ein gutes Produkt hat, ist ein Thema, das mich mittlerweile schon mehr als 7 Jahre begleitet. Diese Leidenschaft mit etwas kombinieren zu dürfen, das mich mein Leben lang schon begleitet – den Sport – ist für mich jeden Tag aufs Neue erfüllend. Als Kampfsportler, der wie viele andere da draußen ambitioniert Sport treibt, mit dem Ziel, immer der Beste in seinem Kreis zu sein, treibt es mich an, unser Produkt an alle Sportler heranzubringen, die genau dasselbe Ziel verfolgen.                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
