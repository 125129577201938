import React from 'react'
import SectionA from './SectionA'
import SectionC from './SectionC'

export default function About() {
  return (
    <>
      <SectionA />
      <SectionC />
    </>
  )
}
